@import 'styles/base.scss';

.base {
  width: 500px;
}

.header {
  padding: 0 24px;
  margin-bottom: 0px;
}

.body {
  padding: 24px;
}

.footer {
  padding: 0 24px 24px;
  margin-top: 16px;
}

.sectionHeader {
  @include font($size: 14px, $lineHeight: 17px, $weight: medium);
  color: map-get($colors, grey-5);
  margin-bottom: 24px;
}
