@import 'styles/base.scss';

.base {
}

.tableHeader {
  margin: 24px;
}

.ellipsisText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table {
  @include column(stretch, stretch);
  flex: 1;
}

.status {
  @include row(flex-start, center);
  gap: 4px;
}

.actions {
  @include row(center, center);
  gap: 8px;
}

.titleContent {
  @include row(flex-start);
  gap: 16px;

  span {
    @include font($size: 18px, $weight: bold);
  }
}

.cellWrapper {
  @include row(flex-start, center);
  gap: 4px;

  svg {
    display: block;
  }
}

.drawerClose {
  position: absolute;
  top: 16px;
  right: 16px;
}

.load {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
