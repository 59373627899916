@import 'styles/base.scss';

.base {
  width: 720px;
}

.soapFieldTitle {
  margin-bottom: 6px;
  p {
    @include row(center, center);
  }

  svg {
    margin-left: 6px;
  }
}

.planModal {
  max-width: 375px;
}

.planModalBody {
  @include font($size: 16px, $lineHeight: 24px);
  p {
  }

  strong {
    @include font($weight: bold);
  }

  ul {
    padding-left: 16px;
    li {
      &:before {
        position: relative;
        content: '•';
        left: -7px;
        top: -1px;
      }
    }
  }
}

.separator {
  margin: 16px 0;
  border-top: solid 1px map-get($colors, grey-2);
}
