@import 'styles/base.scss';

.base {
  width: 720px;
}

.section {
  margin-top: 30px !important;

  &:first-of-type {
    margin-top: 0px !important;
  }
}

.title {
  @include font($size: 20px, $lineHeight: 22px, $weight: bold);
  margin-bottom: 6px;
}

.providerTopInfo {
  font-size: 14px;
  line-height: 22px;
  color: map-get($colors, midnight);
  font-weight: 400;
}

.invalidateButton {
  margin-top: 16px !important;
}

.icon {
  margin-right: 8px;
}

.soapNotesContainer {
  .noteContent {
    margin-top: 8px;
    .noteTitle {
      @include font($weight: bold);
    }
    .noteDescription {
      margin-bottom: 16px;
    }
  }
}

.footer {
  margin-top: 21px;
}

.reasonForDeny {
  margin: 24px 0 8px;
  @include font($size: 14px, $lineHeight: 16px, $color: midnight);
}

.noteHeader {
  margin-bottom: 24px;
  @include row(space-between, center);
  p {
    @include font($size: 14px, $lineHeight: 17px, $weight: bold);
  }

  .noteLabel {
    color: map-get($colors, midnight);
  }

  .showPriorVersion {
    color: map-get($colors, midnight);
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.versionLabel {
  @include row(flex-start, center);
  margin-bottom: 4px;
  @include font($size: 14px, $lineHeight: 30px, $color: midnight);

  .versionHighlight {
    @include font($size: 14px, $lineHeight: 30px, $color: midnight);
    background-color: rgba(map-get($colors, yellowGold), 0.2);
  }
}

.priorVersion {
  border-left: 1px solid map-get($colors, grey-2);
  padding-left: 43px;
  margin-bottom: 25px;

  .priorVersionLabel {
    @include font($size: 14px, $lineHeight: 17px, $color: midnight);
    margin-bottom: 12px;
  }

  .priorVersionNote {
    @include font($size: 16px, $lineHeight: 24px, $color: midnight);
    white-space: pre-wrap;
  }
}

.noteText {
  white-space: pre-wrap;
}

.rejectionNote {
  @include font($size: 16px, $lineHeight: 24px, $color: midnight);
  white-space: pre-wrap;
}

.addAdditionalNote {
  span {
    @include font($size: 12px, $lineHeight: 18px, $weight: bold, $color: midnight);
    letter-spacing: 0.222222px;
  }
}

.yourAdditionalNote {
  @include row(flex-start, center);
  display: inline-flex;
  gap: 8px;
  margin: 8px 0;
  @include font($size: 14px, $lineHeight: 18px, $color: midnight);
}

.additionalNoteEditText {
  [class*='MuiFilledInput-root'] {
    background-color: map-get($colors, grey-7);
    border-radius: 4px;
    padding: 0;
  }

  textarea {
    padding: 12px;
    height: 24px;
    @include font($size: 14px, $lineHeight: 24px, $color: midnight);
    letter-spacing: 0;
  }
}

.additionalNoteText {
  background-color: map-get($colors, grey-7);
  border-radius: 4px;
  padding: 12px;
  @include font($size: 14px, $lineHeight: 24px, $color: midnight);
  letter-spacing: 0;
}

.reviewerInfo {
  @include font($size: 14px, $lineHeight: 24px, $color: grey-5);
  font-style: italic;
  padding-left: 12px;
}

.serviceRequestTable {
  width: 100%;
  table-layout: fixed;
  &,
  td,
  th {
    border: 1px solid map-get($colors, grey-1);
    border-radius: 4px;
  }
  td {
    padding: 4px;
    word-break: break-word;
    div:first-child {
      @include font($size: 12px, $lineHeight: 14px, $color: grey-9, $weight: bold);
    }
    div:not(:first-child) {
      @include font($size: 12px, $lineHeight: 14px, $color: grey-9);
      min-height: 14px;
    }
  }
  border-collapse: collapse;

  .extCheckbox {
    margin-top: 8px;
    @include row(flex-start, center);
    gap: 4px;

    label {
      @include font($size: 12px, $lineHeight: 14px, $color: grey-9, $weight: medium);
    }
  }

  .extDetails {
    margin-left: 36px;
    margin-top: 4px;
  }
}

.signatureLabel {
  @include font($size: 8px, $lineHeight: 10px, $color: midnight);
  font-style: italic;
  margin-top: 4px;
}

.signature {
  margin-top: 8px;
  @include font($family: handwriting, $size: 12px, $lineHeight: 15px, $color: midnight);
  border-bottom: 0.5px solid map-get($colors, midnight);
  width: fit-content;
  min-width: 174px;
}
.noHistoryTitle {
  margin-left: 24px;
}
.soapNoteInfo {
  font-size: 14px;
  white-space: pre-wrap;
}

.approvedOrRejectedIcon {
  margin-left: 10px;
  margin-top: 5px;
}

.info {
  @include row(flex-start, flex-start);
  gap: 8px;
  padding: 16px;
  background-color: map-get($colors, grey-1);
  border-radius: 12px;
  p {
    @include font($size: 14px, $lineHeight: 24px, $color: midnight);
  }
}
