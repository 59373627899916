@import 'styles/base.scss';

.header {
  padding: 20px;

  [class*='description'] {
    @include font($color: grey-5);
  }
}

.body {
  padding: 0 24px;
}

.footer {
  padding: 20px;
  margin-top: 0px;
}

.section {
  padding: 16px;

  .title {
    @include font($size: 16px, $lineHeight: 22px, $color: midnight, $weight: bold);
    margin-bottom: 12px;
  }

  .text {
    padding: 12px;

    @include font($size: 16px, $lineHeight: 24px, $color: midnight);
  }
}
