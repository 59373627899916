@import 'styles/base.scss';

.base {
}

.tableHeader {
  margin: 24px;
}

.tableCellClassName {
  padding: 16px 10px !important;
  line-height: 24px !important;
  align-items: flex-start !important;
}

.row {
  .actions {
    visibility: hidden;
  }
  &:hover {
    .actions {
      visibility: visible;
    }
  }
}

.createdDateCell {
  overflow: visible !important;
}

.createdDate {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;

  span:last-child {
    padding-right: 12px;
  }
}

.statusCellWrapper {
  @include font($size: 14px, $lineHeight: 20px, $color: grey-5, $weight: semibold);
  .reason {
    margin-top: 4px;
    @include font($size: 12px, $lineHeight: 20px, $color: grey-5, $weight: medium);
  }
}

.dateCreatedCellWrapper {
  width: 100%;
  position: relative;
  line-height: 20px;
  @include row(flex-start, center);

  span {
    @include font($size: 12px, $lineHeight: 20px, $color: grey-9, $weight: semibold);
  }

  .aging {
    position: absolute;
    top: 24px;
  }
}

.referralCellWrapper {
  @include row(flex-start, center);
  gap: 4px;

  svg {
    display: block;
  }
}

.patientName {
  max-width: 180px;
}

.tooltip {
  @include font($size: 12px, $lineHeight: 20px, $color: midnight);
}

.active {
  color: map-get($colors, actionable-green);
}

.completed,
.accepted {
  color: map-get($colors, midnight);
}

.revoked {
  color: map-get($colors, warning);
}

.expired,
.draft {
  color: map-get($colors, grey-8);
}

.notes {
  align-items: center !important;
}

.ellipsisText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 24px;
}

.mainNote {
  font-size: 14px;
}

.secondLine {
  @include font($size: 12px, $lineHeight: 20px, $color: grey-9);
}

.icon {
  height: 16px;
}

.table {
  @include column(stretch, stretch);
  flex: 1;
}
