@import 'styles/base.scss';

.label {
  font-size: 13px;
  color: map-get($colors, grey-3);
  font-weight: 400;
}

.noFiles {
  font-style: italic;
}

.filesContainer {
  background-color: map-get($colors, grey-1);
  padding: 16px;
  border-radius: 4px;
  min-height: 50px;
  margin-bottom: 48px;
  position: relative;

  .file {
    display: inline-block;
    background-color: map-get($colors, grey-1);
    text-align: center;
    margin-right: 8px;
    margin-bottom: 8px;
    padding-top: 8px;
    border-radius: 16px;
    cursor: pointer;
    transition: opacity 0.3s ease;

    img {
      width: 60px;
    }
    p {
      @include font($size: 14px, $lineHeight: 20px, $weight: medium);
      background-color: map-get($colors, white);
      border-radius: 16px;
      padding: 2px 8px;
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:hover {
      background-color: map-get($colors, white);
    }

    &--downloading {
      opacity: 0.5;
    }

    &--errored {
      opacity: 0.5;
      background-color: rgba(map-get($colors, error), 0.4);

      p {
        // color: map-get($colors, error);
        background-color: transparent;
      }

      &:hover {
        background-color: rgba(map-get($colors, error), 0.4);
      }
    }
  }
}

.shortFilesContainer {
  min-height: 50px;
  margin-bottom: 24px;
  position: relative;

  .file {
    @include row(flex-start, center);
    background-color: map-get($colors, grey-1);
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 8px;
    padding-top: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    width: 100%;

    svg,
    img {
      margin-right: 8px;
    }

    img {
      width: 20px;
    }
    .infoContainer {
      flex: 1;

      p {
        @include font($size: 14px, $lineHeight: 16px, $weight: medium);
        color: map-get($colors, grey-4);
      }

      .fileInfo {
        width: 100%;
        @include row(space-between, flex-start);
        gap: 4px;
        p {
          &:first-of-type {
            flex: 1;
            word-break: break-word;
            color: map-get($colors, midnight);
          }
        }
      }

      .description {
        color: map-get($colors, grey-4);
      }
    }

    &:hover {
      background-color: map-get($colors, grey-2);
    }

    &--downloading {
      opacity: 0.5;
    }

    &--errored {
      opacity: 0.5;
      background-color: rgba(map-get($colors, error), 0.4);

      p {
        background-color: transparent;
      }

      &:hover {
        background-color: rgba(map-get($colors, error), 0.4);
      }
    }
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.5);

  .bar {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
  }
}
