@import 'styles/base.scss';

.base {
  width: 720px;
}

.section {
  margin-top: 16px !important;
}

.title {
  @include font($size: 14px, $lineHeight: 17px, $weight: bold, $color: midnight);
}

.description {
  @include font($size: 16px, $lineHeight: 24px, $color: midnight);
  word-break: break-word;
}

.rejectButton {
  margin-top: 16px !important;
}

.toggleOriginal {
  @include font($size: 16px, $weight: bold, $color: grey-5);
  text-decoration: underline;
  margin: 6px 0px;
  cursor: pointer;
}

.originalText {
  @include font($size: 16px, $lineHeight: 24px, $color: grey-4);
  background-color: map-get($colors, grey-1);
  border-radius: 6px;
  padding: 14px;
}

.soapNotesContainer {
  .noteContent {
    margin-top: 8px;
    .noteTitle {
      @include font($weight: bold);
    }
    .noteDescription {
      margin-bottom: 16px;
    }
  }
}

.footer {
  margin-top: 80px;
}

.serviceRequestTable {
  width: 100%;
  table-layout: fixed;
  &,
  td,
  th {
    border: 1px solid map-get($colors, grey-1);
    border-radius: 4px;
  }
  td {
    padding: 4px;
    div:first-child {
      @include font($size: 12px, $lineHeight: 14px, $color: grey-9, $weight: bold);
    }
    div:not(:first-child) {
      @include font($size: 12px, $lineHeight: 14px, $color: grey-9);
      min-height: 14px;
    }
  }
  border-collapse: collapse;

  .extCheckbox {
    margin-top: 8px;
    @include row(flex-start, center);
    gap: 4px;

    label {
      @include font($size: 12px, $lineHeight: 14px, $color: grey-9, $weight: medium);
    }
  }

  .extDetails {
    margin-left: 36px;
    margin-top: 4px;
  }
}
