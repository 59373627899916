@import 'styles/base.scss';

.base {
  @include column(center, center);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(map-get($colors, midnight), 0.3);
  pointer-events: none;
  user-select: none;
  font-weight: 100;
  color: map-get($colors, midnight);
  z-index: 99999;

  .icon {
    margin-bottom: 8px;
  }
}
