@import 'styles/base.scss';

.base {
  padding: 0;
  width: 460px;
}

.body {
  padding: 0 40px 70px;
}

.header {
  padding: 20px 30px;
}

.footer {
  margin-top: 0px;
  padding: 0 40px 40px;
}

.section {
  position: relative;
  background-color: map-get($colors, grey-7);
  border-radius: 4px;
  padding: 16px;
  @include column(center, center);
  @include font($size: 14px, $lineHeight: 16px, $color: midnight, $weight: semibold);
}
