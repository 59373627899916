@import 'styles/base.scss';

.navigation {
  @include row(space-between, center);
  background-color: map-get($colors, white);
  border-bottom: solid 1px map-get($colors, grey-2);
  height: 64px;
  padding: 0 12px;

  .hamburger {
    margin-right: 24px;
  }
  .logo {
    height: 24px;
    margin-bottom: 4px;
    user-select: none;
    cursor: pointer;
  }

  .searchInput {
    background-color: map-get($colors, natural);
    & > div {
      padding-left: 8px;
    }
  }

  li {
    display: inline-block;
    margin: 12px;

    a {
      text-decoration: none;
      cursor: pointer;
      color: map-get($colors, midnight);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .logout {
    text-decoration: none;
    cursor: pointer;
    color: map-get($colors, midnight);

    &:hover {
      text-decoration: underline;
    }
  }
}
