@import 'styles/base.scss';

.popoverContainer {
  padding: 16px;
  min-width: 500px;

  .clearFilters {
    @include font($weight: bold);
    cursor: pointer;
    text-decoration: underline;
    text-align: center;
    margin-top: 8px;

    &:hover {
      color: map-get($colors, lush);
    }
  }
}
