@import 'styles/base.scss';

.addButton {
  margin-bottom: 32px !important;
}

.lastSetBy {
  @include font($size: 12px, $lineHeight: 20px);
  color: map-get($colors, grey-9);

  &.disabled {
    opacity: 0.5;
  }
}
