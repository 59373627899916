@import 'styles/base.scss';

.base {
  @include row(space-between, center);

  .content {
    @include row(flex-start, center);
  }
  .leftContent {
    margin-left: 12px;
  }
  .title {
    @include font($size: 18px, $weight: semibold);
  }
  .icon {
    margin-right: 4px;
  }
}

.margin {
  margin-bottom: 24px;
}
