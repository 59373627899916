@import 'styles/base.scss';

.base {
}

.actionTitle {
  margin-top: 24px;
}

.actions {
  @include column(stretch, stretch);
  margin-bottom: 40px;

  & > button {
    min-height: 42px;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px !important;
    }
  }

  .behalfVetWarning {
    @include font($size: 12px, $color: error, $lineHeight: 14px);
    margin: 4px 0;
    text-align: center;
  }
}
