@import 'styles/base.scss';

.icon {
  padding: 5px;
  border-radius: 50%;

  &:hover {
    background-color: rgba(map-get($colors, grey-3), 0.5);
  }
}

.itemIcon {
  margin-right: 12px;
}

.list {
  padding: 6px 0;
  li {
    @include font($size: 14px, $color: midnight);
    @include row(flex-start, center);
    padding: 12px;
    padding-right: 14px;
    cursor: pointer;

    &:hover {
      background-color: map-get($colors, grey-1);
    }

    svg {
      color: map-get($colors, actionable-green);
    }
  }
  .menuItem {
    &--info {
    }

    &--danger {
      color: map-get($colors, error);

      svg {
        color: map-get($colors, error);
      }
    }
  }
}
