@import 'styles/base.scss';

.base {
  width: 730px;
}

.section {
  margin-top: 30px !important;

  &:first-of-type {
    margin-top: 0px !important;
  }
}

.title {
  @include font($size: 20px, $lineHeight: 22px, $weight: bold);
}

.copyWrapper {
  cursor: pointer !important;
  border-radius: 10px;
  transition: backround-color 0.2s;
  &:hover {
    background-color: map-get($colors, grey-2);
  }
}

.currentExpDateHelper {
  @include font($size: 14px, $color: grey-5);
  text-align: center;
}

.files {
  .label {
    font-size: 13px;
    color: map-get($colors, grey-3);
    font-weight: 400;
  }
  .container {
    background-color: map-get($colors, grey-1);
    padding: 16px;
    border-radius: 4px;
    min-height: 100px;

    .file {
      display: inline-block;
      text-align: center;
      margin-right: 8px;
      margin-bottom: 8px;
      padding-top: 8px;
      border-radius: 16px;
      cursor: pointer;
      transition: opacity 0.3s ease;

      img {
        width: 60px;
      }
      p {
        @include font($size: 14px, $lineHeight: 20px, $weight: semibold);
        background-color: map-get($colors, white);
        border-radius: 16px;
        padding: 2px 8px;
        max-width: 200px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &:hover {
        background-color: map-get($colors, white);
      }

      &--downloading {
        opacity: 0.5;
      }

      &--errored {
        opacity: 0.5;
        background-color: rgba(map-get($colors, error), 0.4);

        p {
          // color: map-get($colors, error);
          background-color: transparent;
        }

        &:hover {
          background-color: rgba(map-get($colors, error), 0.4);
        }
      }
    }
  }
}

.footer {
  margin-top: 80px;
}
