@import 'styles/base.scss';

.base {
  width: 730px;
}

.section {
  margin-top: 30px !important;

  &:first-of-type {
    margin-top: 0px !important;
  }
}

.title {
  @include font($size: 20px, $lineHeight: 22px, $weight: bold);
}

.copyWrapper {
  cursor: pointer !important;
  border-radius: 10px;
  transition: background-color 0.2s;
  &:hover {
    background-color: map-get($colors, grey-2);
  }
}

.currentExpDateHelper {
  @include font($size: 14px, $color: grey-5);
  text-align: center;
}

.footer {
  margin-top: 80px;
}

.appointmentId {
  @include row(flex-start, center);
  gap: 8px;
  padding: 8px;
  margin: auto;
  width: fit-content;
}
