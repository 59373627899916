@import 'styles/base.scss';

.base {
  display: block;
  width: 100%;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.header {
  @include row(space-between, center);
  cursor: pointer;
  padding: 8px 0;

  .title {
    @include row(flex-start, center);
    @include font($size: 20px, $lineHeight: 22px, $weight: bold);
  }

  .prefixIcon {
    margin-right: 6px;
  }

  .icon {
  }

  .iconAnimate {
    transition: transform 0.2s ease;
  }

  .iconRotate {
    transform: rotate(180deg);
  }

  &--borders {
    border-top: solid 1px map-get($colors, grey-2);
  }
}

.content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.2s ease;

  &--borders {
    border-bottom: solid 1px map-get($colors, grey-2);
    margin-bottom: -1px; // to avoid double borders when using multiple toggle sections
  }
}
