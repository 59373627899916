@import 'styles/base.scss';

.container {
  background-color: map-get($colors, white);
  border-left: solid 1px map-get($colors, grey-2);
  position: relative;
  height: 100%;
  width: 646px;
  transition: width 0.3s ease;
  overflow-y: auto;

  &--hidden {
    width: 0px;
  }
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 646px;
  min-height: 100%;
}

.header {
  @include row(space-between, center);
  margin-bottom: 24px;
  position: sticky;
  top: 0px;
  padding: 32px 32px 12px;
  background-color: rgba(map-get($colors, white), 0.9);
  z-index: 10;

  .title {
    @include font($size: 16px, $lineHeight: 24px, $weight: semibold, $color: grey-4);
    margin: 0;
  }

  .icon {
    margin-left: 16px;
  }
}

.content {
  padding: 0px 32px 32px;
  margin-top: 24px;
}

.actions {
  background-color: red;
  @include row(center, center);
  background-color: map-get($colors, white);
  border-top: solid 1px map-get($colors, grey-2);
  width: calc(100%);
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 16px 16px;

  & > * {
    flex: 1;
    margin: 0 8px !important;
  }
}

.alertWrapper {
  margin: 16px;
  margin-bottom: 0px;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;

  .bar {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
  }
}
