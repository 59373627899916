@import 'styles/base.scss';

.base {
}

.tableHeader {
  margin: 24px;
}

.actionButton:not(:last-child) {
  margin-right: 8px !important;
}

.contactIconWrapper {
  @include column(center, center);
  height: 45px;
  width: 100%;
  position: relative;

  p {
    @include font($size: 8px, $lineHeight: 10px, $color: grey-4);
  }
}

.ellipsisText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table {
  @include column(stretch, stretch);
  flex: 1;
}
