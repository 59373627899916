@import 'styles/base.scss';

.base {
  width: 500px;
}

.header {
  padding: 0 24px;
  margin-bottom: 0px;
}

.body {
  padding: 24px;
}

.footer {
  padding: 0 24px 24px;
  margin-top: 16px;
}
