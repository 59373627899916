$colors: (
  white: #ffffff,
  natural: rgb(246, 246, 244),
  lush: #1e890a,
  actionable-green: #0a7938,
  actionable-green-light: #f4faf7,
  lush-light: #d3e6d0,
  teal: #3b959f,
  teal-light: #faf9fa,
  teal-light-alt: #ebf4f5,
  dusty-blue: #507ea4,
  // slightly darker than teal-light
  error: #ef0000,
  error-dark: #d40b0b,
  grey-1-alt: #f7f8f8,
  grey-1: #f2f2f2,
  grey-2: #e2e2e2,
  grey-3: #c2c2c2,
  grey-4: #999999,
  grey-5: #646464,
  grey-6: #4a4a4a,
  grey-7: #f6f6f4,
  grey-8: #747474,
  grey-9: #595959,
  midnight: #333,
  orange: #fd6b19,
  orange-alt: #f5806a,
  warning: #c3551d,
  /* not from pallete */ midnight-hover: #474747,
  yellow: #e7b334,
  yellowGold: #d3b15c,
  p-3: #646464,
  beige: #f5f0e9,
  /* needs to be changed to the new styleguide */ old-footer: #323a24,
  old-more-screen-grey: #f7f7f7,
  old-social-icon: #80b518,
  old-copyright-text: #a9a8a8,
);

$font-family: (
  primary: 'DomaineDisplay',
  primary-italic: 'DomaineDisplayItalic',
  secondary: 'HKGrotesk',
  secondary-light: 'HKGrotesk-Light',
  secondary-medium: 'HKGrotesk-Medium',
  secondary-semibold: 'HKGrotesk-SemiBold',
  secondary-bold: 'HKGrotesk-Bold',
  handwriting: 'Bradley-Hand-Bold',
  old: 'Open Sans',
);

$mobileWidth: 767px;
$desktopWidth: 768px;
$wideWidth: 1500px;
