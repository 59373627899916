@import 'styles/base.scss';

.providerTopInfo {
  @include row(flex-start, center);
  .avatar {
    min-width: 60px;
    min-height: 60px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: map-get($colors, grey-2);
  }

  p {
    @include font($weight: bold);
  }
}
