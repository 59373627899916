@import 'styles/base.scss';

.base {
  width: 460px;
}

.body {
  padding: 15px 30px 30px;
}

.header {
  padding: 20px 30px;
}

.footer {
  margin-top: 0px;
}

.section {
  position: relative;
  background-color: map-get($colors, grey-7);
  border-radius: 4px;
  padding: 8px;
  @include row(flex-start, center);
  @include font($size: 14px, $lineHeight: 16px, $color: midnight, $weight: semibold);

  svg {
    margin-right: 8px;
  }

  .loading {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: map-get($colors, dusty-blue);
    box-shadow: 0px 0px 7px rgba(map-get($colors, dusty-blue), 0.7);
    border-radius: 4px;
    height: 5px;
  }
}
