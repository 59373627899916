@import 'styles/base.scss';

.referralCellWrapper {
  @include row(flex-start, center);
  gap: 4px;
  @include font($size: 14px, $lineHeight: 24px, $weight: bold);
  text-decoration-line: underline;

  svg {
    display: block;
  }
}

.ellipsisText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 24px;
  color: map-get($colors, midnight);
}

.bookNewAppointment {
  margin-left: auto !important;
  cursor: pointer;
  @include font($size: 14px, $lineHeight: 24px, $weight: bold, $color: midnight);
  margin-bottom: 8px;
}
