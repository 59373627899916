@import 'styles/base.scss';

.base {
}

.tableHeader {
  margin: 24px;
}

.table {
  @include column(stretch, stretch);
  flex: 1;
}
