@import 'styles/base.scss';

.base {
  width: 650px;
}

.section {
  margin-top: 30px !important;

  &:first-of-type {
    margin-top: 0px !important;
  }
}

.title {
  @include font($size: 20px, $lineHeight: 22px, $weight: bold);
}

.sectionTitle {
  @include font($weight: bold, $size: 20px);
  margin-top: 8px;
  margin-bottom: 8px;
}

.soapNotesContainer {
  .noteContent {
    margin-top: 8px;
    .noteTitle {
      @include font($weight: bold);
    }
    .noteDescription {
      margin-bottom: 16px;
    }
  }
}

.footer {
  margin-top: 80px;
}
