@import 'styles/base.scss';

.base {
  width: 500px;
}

.body {
  padding: 0 24px;
}

.footer {
  padding: 0 24px 24px;
  margin-top: 8px;
}

.actionLabel {
  @include row(center, center);
  gap: 8px;
}
