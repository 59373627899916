@import 'styles/base.scss';

.base {
  width: 992px;
}

.header {
  padding: 0;
  margin: auto;
  margin-bottom: 0;
}

.body {
  padding: 0 16px;
}

.footer {
  padding: 8px;
  margin-top: 0px;
}
