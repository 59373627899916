@import 'styles/base.scss';

.pagination {
  margin-left: auto;
}

.disabled {
  color: map-get($colors, grey-3) !important;
  cursor: not-allowed !important;
}
