@import 'styles/base.scss';

.tableWrapper {
  min-height: 150px;

  .table {
    border: none;
  }

  .tableHeaderCell {
    @include font($size: 14px, $lineHeight: 24px, $color: grey-4);
    user-select: none;
    outline: none !important;
  }

  .tableCell {
    @include font($size: 14px, $lineHeight: 24px, $color: midnight);
    outline: none !important;
  }

  :global(.MuiDataGrid-row) {
    border-left: solid 3px transparent;
    cursor: pointer;
  }

  .tableRowActive {
    @include font($size: 14px, $lineHeight: 24px, $weight: bold);
    background-color: rgba(0, 0, 0, 0.08) !important;
    border-left-color: map-get($colors, lush);
  }

  :global(.MuiDataGrid-columnSeparator) {
    opacity: 0 !important;
  }

  :global(.MuiDataGrid-colCellWrapper) {
    min-height: 56px;
  }
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.topContent {
  @include row(space-between, center);
  padding-top: 16px;
  margin: 16px 24px 24px;

  .referralAmount {
    @include font($size: 16px, $lineHeight: 24px, $weight: bold);
  }

  .fieldsWrapper {
    @include row(flex-end, flex-end);

    & > * {
      margin-left: 32px;
    }
  }
}

.loader {
  min-height: 4px;

  .linearProgress {
    background-color: white;
    opacity: 0.3;

    :global(.MuiLinearProgress-bar) {
      background-color: map-get($colors, lush);
    }
  }
}
