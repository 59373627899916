@import 'styles/base.scss';

.button {
  min-height: 42px;
}

.danger {
  background-color: transparent !important;
  color: map-get($colors, error) !important;
}

.dangerContained {
  background-color: map-get($colors, error) !important;
  color: map-get($colors, white) !important;
}

.submitLoading {
  color: map-get($colors, white) !important;

  &--dark {
    color: map-get($colors, midnight) !important;
  }
}
