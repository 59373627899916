@import 'styles/base.scss';

.header {
  padding: 20px;
}

.footer {
  padding: 20px;
  margin-top: 0px;
}
