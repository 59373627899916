@import 'styles/base.scss';

.base {
}

.header {
}
.body {
  padding: 0 16px;
}
.footer {
  padding: 0 16px 16px;
}
