@import 'styles/base.scss';

.base {
  padding: 0;
  width: 460px;
}

.body {
  padding: 0 40px 70px;
}

.header {
  padding: 20px 30px;
}

.footer {
  margin-top: 0px;
  padding: 0 40px 40px;
}

.section {
  position: relative;
  background-color: map-get($colors, grey-7);
  border-radius: 4px;
  padding: 40px;
  @include column(center, center);
  @include font($size: 14px, $lineHeight: 16px, $color: midnight, $weight: semibold);

  > svg {
    margin-bottom: 8px;
  }

  .filename {
    width: 100%;
    @include row(center, center);
    background-color: map-get($colors, white);
    border-radius: 6px;
    padding: 12px 20px;

    > svg {
      margin-left: 15px;
    }
  }
}
