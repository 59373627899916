@import 'styles/base.scss';

.segments {
  width: 100%;
  overflow: hidden;

  label {
    display: block;
    width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 8px;
    user-select: none;
    @include font($size: 18px, $lineHeight: 24px, $color: midnight, $weight: semibold);
  }

  .container {
    @include row(stretch, flex-start);
    gap: 2.5px;
    outline: none;
    padding: 4px;
    border: 1px solid map-get($colors, grey-3);
    border-radius: 12px;

    hr {
      height: 28px;
      background-color: map-get($colors, grey-3);
    }
  }

  .extraText {
    @include font($size: 14px, $lineHeight: 24px, $color: grey-9);
    margin-top: 8px;
  }
}

.wrapLabel {
  label {
    overflow: visible;
    height: auto;
    white-space: normal;
    text-overflow: inherit;
    margin-bottom: 4px;
    position: relative;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.readOnly {
  opacity: 0.5;
  pointer-events: none;
}

.item {
  flex: 1;
  @include font($size: 16px, $color: midnight, $lineHeight: 24px, $weight: medium);
  background: white;
  padding: 12px 8px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 8px;
  cursor: pointer;

  &.active {
    @include font($color: white, $weight: bold);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    background: map-get($colors, actionable-green);
  }

  .invisible {
    display: none;
  }
}

.invalid {
  .container {
    border-color: map-get($colors, error);
  }
}

.validationText {
  min-height: 17px;
  margin-top: 5px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  width: 100%;
  color: map-get($colors, error);
  @include font($size: 14px, $lineHeight: 17px);
}
