@import 'styles/base.scss';

.base {
  width: 720px;
}

.section {
  margin-top: 30px !important;

  &:first-of-type {
    margin-top: 0px !important;
  }
}

.title {
  @include font($size: 20px, $lineHeight: 22px, $weight: bold);
  margin-bottom: 6px;
}

.emphasis {
  @include font($weight: bold);
  text-decoration: underline;
}

.headerContent {
  @include font($size: 14px, $lineHeight: 22px, $color: midnight);
}

.previousVersionContainer {
  border-left: 1px solid map-get($colors, grey-2);
  padding-left: 24px;
  margin: 24px 0;
}

.versionLabel {
  @include font($size: 12px, $lineHeight: 20px, $color: midnight, $family: secondary);

  span {
    @include font($family: secondary-semibold);
  }
}

.note {
  @include font($size: 16px, $lineHeight: 24px, $color: midnight);
  white-space: pre-wrap;
}

.previousVersion {
  @include font($size: 16px, $lineHeight: 24px, $color: grey-9);
  white-space: pre-wrap;
}

.rejectContainer {
  margin-top: 24px;
  @include row(flex-start, flex-start);
  gap: 24px;
}

.avatar {
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: map-get($colors, grey-2);
  @include font($size: 18px, $lineHeight: 22px, $color: grey-9, $family: secondary-bold);
  @include row(center, center);
}

.rejectHeader {
  @include font($size: 12px, $lineHeight: 20px, $color: midnight, $family: secondary);

  .reviewerName {
    @include font($family: secondary-semibold);
  }
}

.rejectionNote {
  @include font($size: 14px, $lineHeight: 24px, $color: midnight);
  white-space: pre-wrap;
}
