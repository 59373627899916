@import 'styles/base.scss';

.base {
}

.tableHeader {
  margin: 24px;
}

.tableCellClassName {
  padding: 16px 10px !important;
  line-height: 24px !important;
  align-items: flex-start !important;
}

.row {
  .actions {
    visibility: hidden;
  }
  &:hover {
    .actions {
      visibility: visible;
    }
  }
}

.table {
  @include column(stretch, stretch);
  flex: 1;
}
