@import 'styles/base.scss';

.base {
  min-width: 600px;
}

.header {
  padding: 0 24px;
  margin-bottom: 0px;
}

.whatHappensNext {
  p {
    @include font($weight: bold);
  }
  ul {
    list-style-type: none;
    position: relative;
    margin-left: 24px;

    li {
      line-height: 24px;
      &::before {
        content: '•';
        position: absolute;
        left: -16px;
        font-size: 16px;
      }
    }
  }
}

.body {
  padding: 24px;
}

.footer {
  padding: 0 24px 24px;
  margin-top: 16px;
}
