@import 'styles/base.scss';

.base {
  width: 500px;
}

.cardGroup {
  width: 100%;
}

.body {
  padding: 0 32px;
}

.footer {
  padding: 0 32px 32px;
  margin-top: 16px;
}

.label {
  display: block;
  @include font($size: 18px, $lineHeight: 24px, $weight: semibold, $color: midnight);
  margin: 8px 0;

  &:not(:first-of-type) {
    margin-top: 32px;
  }
}

.line {
  margin-top: 4px;
  @include font($size: 16px, $lineHeight: 24px, $weight: semibold, $color: midnight);
}

.addButton {
  margin: auto !important;
  display: flex !important;
  margin-top: 32px !important;
}
