@import 'styles/base.scss';

.base {
  width: 650px;
}

.header {
  padding: 20px;
}

.body {
  padding: 0 72px;
}

.footer {
  padding: 40px 72px;
  margin-top: 0px;
}

.section {
  margin-top: 10px;
  border-top: 1px solid #e2e2e2;
}

.fileHeader {
  @include row(space-between, center);

  > div {
    @include row(center, center);

    svg {
      margin-left: 4px;
    }
  }
}

.toggleSection {
  [class*='title'] {
    font-size: 14px !important;
    @include font($color: midnight);
  }
}

.document {
  @include row(space-between, center);
  margin: 4px 0;
  padding: 14px 16px;
  background-color: map-get($colors, grey-7);
  border-radius: 4px;

  .action {
    @include row(flex-start, center);
    @include font($size: 12px, $lineHeight: 14px, $color: dusty-blue);

    .icon {
      margin-left: 20px;
      width: 24px;
      height: 24px;
      @include row(center, center);

      .emptyCircle {
        border: 1px solid map-get($colors, midnight);
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
  }

  .filename {
    @include font($size: 14px, $lineHeight: 24px, $color: midnight);
    text-align: left;
    text-decoration: underline;

    &.selected {
      @include font($weight: bold);
    }
  }
}
