@import 'styles/base.scss';

.base {
}

.tableHeader {
  margin: 24px;
}

.actionButton:not(:last-child) {
  margin-right: 8px !important;
}
