@import 'styles/base.scss';

.base {
}

.tableHeader {
  margin: 24px;
}

.dateCreatedCellWrapper {
  @include column(flex-start, center);

  p {
    font-size: 14px;
  }

  .agingTag {
    font-size: 10px;
    border-radius: 6px;
    background-color: map-get($colors, error);
    color: white;
    line-height: 10px;
    padding: 3px 5px;
    // width: 60px;
    margin: 0px;
  }
}

.referralCellWrapper {
  @include row(flex-start, center);

  .inOfficeIcon {
    display: inline;
  }
}

.actionButton:not(:last-child) {
  margin-right: 8px !important;
}

.ellipsisText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table {
  @include column(stretch, stretch);
  flex: 1;
}
