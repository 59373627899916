@import 'styles/base.scss';

.base {
  width: 460px;
}

.currentExpDateHelper {
  @include font($size: 14px, $color: grey-5);
  text-align: center;
}
