@import 'styles/base.scss';

.base {
  width: 550px;
}

.copyWrapper {
  cursor: pointer !important;
  border-radius: 10px;
  transition: backround-color 0.2s;
  &:hover {
    background-color: map-get($colors, grey-2);
  }
}

.linkContainer {
  text-align: center;
}
.link {
  @include font($weight: bold);
}

.header,
.body,
.footer {
  padding-left: 30px;
  padding-right: 30px;
}
