@import 'styles/base.scss';

.alertsContainer {
  @include column(flex-start, flex-end);
  position: fixed;
  bottom: 20px;
  left: calc(50vw - 200px);
  width: 400px;
  height: calc(100vh - 100px);
  pointer-events: none;
  overflow: hidden;
  z-index: 9999;
}

.alert {
  width: 100%;
  margin-top: 10px;
}

.description {
  &,
  & > span,
  & > p {
    @include font($size: 14px, $lineHeight: 20px);
  }
}
