@import 'styles/base.scss';

.base {
  width: 500px;
}

.body {
  padding: 0 24px;
}

.footer {
  padding: 0 24px 24px;
}

.reason {
  margin: 16px 0 !important;
}

.section {
  margin-top: 16px !important;

  &:first-of-type {
    margin-top: 0px !important;
  }
}
