@import 'styles/base.scss';

.base {
  margin-bottom: 48px;
}

.addCommentLink {
  @include row(flex-start, center);
  @include font($size: 16px, $lineHeight: 24px, $weight: semibold);
  cursor: pointer;

  .icon {
    margin-left: 8px;
  }
  &:hover {
    text-decoration: underline;
  }
}

.newComment,
.editComment {
  .input {
    margin: 8px 0;
  }
  .button {
    margin-right: 8px;
    min-width: 90px;
  }
}

.newComment {
  background-color: map-get($colors, grey-1);
  padding: 4px 12px 12px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.commentsContainer {
  .comment {
    margin-bottom: 16px;
    position: relative;

    .topper {
      @include font($size: 12px, $lineHeight: 22px, $color: grey-4);
      margin-bottom: 8px;

      .name {
        @include font($weight: semibold);
        margin-right: 8px;
      }
    }
    .text {
      @include font($size: 16px, $lineHeight: 24px, $color: black);
    }
    .actions {
      margin-top: 8px;

      .icon {
        margin-right: 8px;
        cursor: pointer;

        &:hover {
          color: map-get($colors, lush);
        }
      }
    }

    &--self {
      .topper {
        .name {
          color: map-get($colors, lush);
        }
      }
    }

    &--new {
      &:after {
        content: '';
        position: absolute;
        left: -14px;
        top: 7px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: map-get($colors, lush);
      }
    }
  }
}

.noComments {
  font-style: italic;
}

.viewOlderLink {
  @include font($size: 16px, $lineHeight: 24px, $weight: semibold);
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
