@import 'styles/base.scss';

.icon {
  padding: 5px;
  border-radius: 50%;
  position: relative;

  &:hover {
    color: map-get($colors, actionable-green);
    background-color: rgba(map-get($colors, grey-3), 0.5);
  }
}

.itemIcon {
  margin-right: 12px;
}

.list {
  padding: 6px 0;
  li {
    @include font($size: 14px, $color: black);
    @include row(flex-start, center);
    padding: 12px;
    padding-right: 14px;
    cursor: pointer;

    &:hover {
      background-color: map-get($colors, grey-1);
    }
  }
}
