@import 'styles/base.scss';

.base {
}

.tableHeader {
  margin: 24px;
}

.actions {
  display: flex;
  gap: 8px;
}

.table {
  @include column(stretch, stretch);
  flex: 1;
}

.patientName {
  max-width: 180px;
}

.tooltip {
  @include font($size: 12px, $lineHeight: 20px, $color: midnight);
}

.cell {
  @include row(flex-start, center);
  @include font($size: 14px, $lineHeight: 24px, $color: midnight, $weight: medium);
  gap: 4px;

  svg {
    display: block;
  }
}

@mixin ellipsis {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ellipsisText {
  @include ellipsis;
}

.multilineCell {
  @include column(flex-start, flex-start);
  overflow: hidden;

  span:first-child {
    @include font($size: 14px, $lineHeight: 24px, $color: midnight, $weight: medium);
    @include ellipsis;
  }

  span:last-child {
    @include font($size: 12px, $lineHeight: 20px, $color: grey-9, $weight: medium);
    @include ellipsis;
  }
}

.tableCellClassName {
  font-family: map-get($font-family, secondary-medium) !important;
  padding: 16px 10px !important;
  line-height: 24px !important;
}
