@import 'styles/base.scss';

.base {
}

.name {
  @include font($size: 12px, $lineHeight: 20px, $weight: semibold, $color: grey-9);
}

.note {
  @include font($size: 16px, $lineHeight: 24px, $weight: medium, $color: midnight);
  word-break: break-word;
}

.label {
  display: block;
  @include font($size: 14px, $lineHeight: 18px, $weight: medium, $color: grey-9);
  margin-bottom: 8px;
}
