@import 'styles/base.scss';

.base {
  width: 500px;
}

.cardGroup {
  width: 100%;
}

.body {
  padding: 0 32px;
}

.footer {
  padding: 0 32px 32px;
}

.label {
  @include font($size: 18px, $lineHeight: 24px, $weight: semibold, $color: midnight);
  margin-bottom: 8px;
}

.referralNumber {
  @include font($size: 16px, $lineHeight: 24px, $weight: semibold, $color: midnight);
}

.line {
  margin-top: 4px;
  @include font($size: 14px, $lineHeight: 24px, $weight: medium, $color: grey-9);
}
