@import 'styles/base.scss';

.base {
  width: calc(720px + 32px * 2);
}

.header {
  padding: 0;
  margin: auto;
  margin-bottom: 0;
}

.body {
  padding: 0 32px;
}

.footer {
  padding: 32px;
  margin-top: 0px;
}
