@import 'styles/base.scss';

.popoverContainer {
  padding: 28px 24px 16px;
  width: 600px;

  .clearFilters {
    @include font($weight: semibold, $color: grey-4);
    cursor: pointer;
    text-align: center;
    margin-top: 16px;

    &:hover {
      color: map-get($colors, lush);
    }
  }
}

.inlineFilter {
  @include row(flex-start, center);
  background-color: map-get($colors, lush);
  opacity: 0.8;
  padding: 0 8px;
  padding-right: 4px;
  border-radius: 8px;
  color: white;
  margin-right: 8px;

  &:last-child {
    margin-right: 0px;
  }

  .deleteFilterIcon {
    margin-left: 2px;
  }
}
