@import 'styles/base.scss';

.menu {
  background-color: map-get($colors, natural);
  width: 50px;
  position: relative;
  transition: all 0.3s ease;
  overflow-x: hidden;
  overflow-y: auto;

  .inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 173px;
    margin-bottom: 20px;

    ul {
      padding-top: 30px;

      a {
        text-decoration: none;
      }

      li {
        @include row(flex-start, center);
        height: 50px;
        padding: 8px;
        color: map-get($colors, midnight);
        margin-bottom: 30px;
        border-left: solid 3px transparent;
        user-select: none;

        .icon {
          margin-right: 16px;
        }

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
        }

        &:hover {
          color: map-get($colors, lush);
        }
      }

      .activeLink {
        li {
          background-color: map-get($colors, white);
          color: map-get($colors, lush);
          border-color: map-get($colors, lush);

          p {
            font-weight: bold;
          }
        }
      }
    }
  }

  .expandIcon {
    position: fixed;
    bottom: 16px;
    left: 8px;
    transition: transform 0.3s ease, left 0.3s ease;
    background-color: rgba(map-get($colors, natural), 0.75);
    border-radius: 50%;
  }

  &--expanded {
    width: 173px;

    .expandIcon {
      transform: rotate(-180deg);
    }
  }

  &--hidden {
    width: 0px;

    .expandIcon {
      left: -40px;
    }
  }
}
