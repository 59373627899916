@import 'styles/base.scss';

.content {
  @include row(stretch, stretch);
  flex: 1;
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 101px);

  // within body > content, it is the left container
  &-main {
    @include column(stretch, stretch);
    flex: 1;
    overflow: auto;

    .alert {
      margin: 24px;
    }

    .headerContainer {
      @include row(space-between, flex-start);
      margin: 24px;
    }

    &-children {
      flex: 1;
      @include column(stretch, stretch);
    }
  }

  // within body > content, it is the right container (for side panel, etc)
  &-side {
    overflow: auto;
  }

  // if margin prop is passed, will add margin to the children nested content
  &--hasMargins {
    .content-main-children {
      margin-left: 24px;
      margin-right: 24px;
    }
  }
}

.backdrop {
  @include row(center, center);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(map-get($colors, white), 0.7);
  z-index: 999;
  opacity: 1;
  transition: opacity 0.2s ease;

  &--hidden {
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }

  .spinner {
    color: map-get($colors, lush);
  }
}
