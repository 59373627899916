@import 'styles/base.scss';
.header {
  padding: 20px;
}

.body {
  padding: 20px;
}

.footer {
  padding: 20px;
}
