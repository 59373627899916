@import 'styles/base.scss';

.base {
}

.tableHeader {
  margin: 24px;
}

.table {
  @include column(stretch, stretch);
  flex: 1;
}

.tableCellClassName {
  padding: 16px 10px !important;
  line-height: 24px !important;
  align-items: flex-start !important;
}

.eFaxLink {
  @include font($size: 14px, $lineHeight: 20px, $color: midnight);
  text-decoration: underline;
  cursor: pointer;
}

.ellipsisText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 24px;
}

.recentNote {
  font-size: 14px;
}

.eFaxCell {
  @include row(flex-start, center);
  gap: 4px;
}

.eFaxTooltip {
  @include font($size: 12px, $lineHeight: 20px, $color: midnight);
  color: map-get($colors, midnight);
  gap: 4px;
  @include row(center, center);
  word-break: break-all;
}
