@import 'styles/base.scss';

.base {
}

.tableHeader {
  margin: 24px;
}

.table {
  @include column(stretch, stretch);
  flex: 1;
}

.tableCellClassName {
  padding: 16px 10px !important;
  line-height: 24px !important;
  align-items: flex-start !important;
}

.cellWrapper {
  @include row(flex-start, center);
  gap: 4px;

  svg {
    display: block;
  }
}

.notes {
  align-items: center !important;
}

.ellipsisText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 24px;
}

.drawerClose {
  position: absolute;
  top: 16px;
  right: 16px;
}

.load {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mainNote {
  font-size: 14px;
}

.secondLine {
  @include font($size: 12px, $lineHeight: 20px, $color: grey-9);
}

.tooltip {
  @include font($size: 12px, $lineHeight: 20px, $color: midnight);
}

.addressTooltip {
  color: map-get($colors, midnight);
  gap: 4px;
  @include row(center, center);
}
