@import 'styles/base.scss';

.base {
  width: 900px;
}

.referralPdfIcon {
  max-height: 50px;
}

.topFields {
  text-align: center;
  margin-bottom: 24px;

  .button {
    min-width: 100%;
  }

  .fileError {
    color: map-get($colors, error);
  }
  .successHelperText {
    @include font($size: 14px, $lineHeight: 24px, $color: lush);
  }
}

.body {
  padding: 0;

  .veteranFields,
  .referralFields,
  .facilityFields,
  .caregiverFields,
  .documentationFields,
  .servicesField,
  .policyHolderFields {
    padding: 0 100px 24px;
  }
  .optionalFields {
    padding: 24px 100px;
    margin: 24px 0;
    background-color: map-get($colors, grey-1);

    &-description {
      @include font($size: 14px, $lineHeight: 24px, $color: grey-4);
    }
  }

  .documentationFileContainerLabel {
    @include font($size: 14px, $color: grey-5);
  }

  .documentationFilesContainer {
    .documentationFile {
      @include row(space-between, center);
      padding: 8px;
      background-color: map-get($colors, natural);
      margin-bottom: 8px;
      border-radius: 4px;

      .documentationFileDeleteIcon {
        cursor: pointer;

        &:hover {
          color: map-get($colors, error);
        }
      }
    }
  }
}

.icd10TrashIcon {
  position: absolute;
  right: -32px;
  top: calc(50%);
  cursor: pointer;

  &:hover {
    color: map-get($colors, error);
  }
}

.addDiagnosisLink {
  @include font($weight: bold);
  @include row(flex-start, center);
  margin-bottom: 24px;
  cursor: pointer;
  width: fit-content;

  .icon {
    margin-right: 6px;
  }
}

.piiWarning {
  @include font($size: 14px, $lineHeight: 24px, $weight: bold);
  margin-top: 4px;
}

.orDivider {
  @include font($size: 14px, $lineHeight: 24px, $weight: semi-bold);
}

.alert {
  margin-top: 16px;
  @include font($size: 14px, $lineHeight: 24px, $weight: bold, $color: error);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
