@import 'styles/base.scss';

.base {
  width: 560px;
}

.body {
  padding: 0 24px;
}

.footer {
  padding: 0 24px 24px;
}

.rejectionNote {
  margin: 16px 0 !important;
}
