@import 'styles/base.scss';

// Main div where mav elements and body is positioned
.base {
  @include row(stretch, stretch);
  height: calc(100vh - 64px);
}

// The div holding all the content within the navbar and sidebar
.body {
  @include column(stretch, stretch);
  width: 100%;
}

// The tabs container, which is kept out of .content to treat is as an "always visible" nav element (won't go away with scroll)
.tabs {
  padding: 0 24px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  user-select: none;
  z-index: 1;

  .faded {
    opacity: 0.5;
  }

  li {
    display: inline-block;
    padding: 12px 0 8px;
    margin-right: 38px;
    font-size: 14px;
    color: map-get($colors, midnight);
    border-bottom: solid 3px transparent;

    &:hover {
      border-color: map-get($colors, lush);
    }
  }

  .tabSeparator {
    @include font($size: 14px, $color: grey-4, $weight: bold);
    margin-right: 24px;
    border-left: solid 1px map-get($colors, grey-2);
    padding: 8px 0 8px;
    padding-left: 16px;
    height: 100%;
  }

  .activeTab {
    li {
      font-weight: bold;
      border-color: map-get($colors, lush);
    }
  }
}

// The div rendering the left and right row content within the body (needed to allow for side-panel)
.content {
  @include row(stretch, stretch);
  flex: 1;
  position: relative;
  overflow: hidden;

  // within body > content, it is the left container
  &-main {
    @include column(stretch, stretch);
    flex: 1;
    overflow: auto;

    .alert {
      margin: 24px;
    }

    .headerContainer {
      @include row(space-between, flex-start);
      margin: 24px;
    }

    &-children {
      @include column(stretch, stretch);
      flex: 1;
    }
  }

  // within body > content, it is the right container (for side panel, etc)
  &-side {
    overflow: auto;
  }

  // if margin prop is passed, will add margin to the children nested content
  &--hasMargins {
    .content-main-children {
      margin: 24px;
    }
    .content-main .headerContainer,
    .content-main .alert {
      margin-bottom: 0px;
    }
  }
}

.backdrop {
  @include row(center, center);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(map-get($colors, white), 0.7);
  z-index: 999;
  opacity: 1;
  transition: opacity 0.2s ease;

  &--hidden {
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }

  .spinner {
    color: map-get($colors, lush);
  }
}
