@import 'styles/base.scss';

.base {
  @include column(stretch, stretch);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.3);
  width: 500px;
  margin: auto auto;
  transition: transform 0.2s ease;
  transform: translateY(100px);
}

.show {
  transform: translateY(0px);
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.5);

  .bar {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
  }
}

.iconsBar {
  @include row(space-between, center);
  pointer-events: none;
  height: 48px;
  padding: 0 12px;
  position: sticky;
  border-radius: 10px;
  overflow: hidden;
  z-index: 999;
  top: -80px; // right now, this is based on backdrop padding (so they need to match)

  @include for-size(mobile) {
    top: -10px;
  }

  &-inner {
    pointer-events: all;
    background-color: red;
  }
}

.iconsWrapper {
  pointer-events: all;
}

.header {
  padding: 12px 75px 0px;
  margin-bottom: 24px;
  position: relative;
  text-align: center;

  .mainIcon {
    margin-bottom: 16px;
  }
  .title {
    @include font($size: 24px, $lineHeight: 36px, $weight: bold);
    margin-bottom: 8px;
    text-align: center;
  }
  .description {
    @include font($size: 16px, $lineHeight: 24px);
    text-align: center;
  }
  .alert {
    margin-bottom: 24px;
  }
}

.body {
  padding: 0 75px;
  flex: 1;
}

.footer {
  padding: 0px 75px 75px;
  margin-top: 56px;

  &--noHeight {
    padding: 0px;
  }

  .actions {
    @include row(stretch, stretch);
    & > button {
      margin-right: 8px;
      min-height: 42px;

      &:last-child {
        margin-right: 0px;
      }
    }

    &--stack {
      @include column(stretch, stretch);
    }
  }

  .submitLoading {
    color: map-get($colors, white);
  }
}
