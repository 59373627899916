@import 'styles/base.scss';

.container {
  @include row(flex-start, center);
  gap: 8px;
  flex-wrap: wrap;
}

.preferencesIcon {
  border-radius: 3px;
  color: map-get($colors, grey-6);
  background-color: map-get($colors, grey-2);
  &Active {
    color: map-get($colors, white);
    background-color: map-get($colors, actionable-green);
  }
}

.preferencesIconDisabled {
  cursor: not-allowed;
  filter: grayscale(1);
}

.searchLabel,
.filterLabel {
  display: inline-block;
  @include font($size: 18px, $lineHeight: 24px, $weight: semibold, $color: midnight);
}

.filterLabel {
  margin-bottom: 8px;
}

.popoverContainer {
  padding: 24px;
  width: 600px;
  box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  border-radius: 12px;
}

.inlineFilter {
  @include row(flex-start, center);
  @include font($size: 12px, $lineHeight: 14px, $weight: semibold, $color: white);
  display: inline-flex;
  gap: 2px;
  background-color: map-get($colors, actionable-green);
  padding: 2px 3px 2px 8px;
  border-radius: 40px;

  &.permanentFilter {
    padding-right: 8px;
    height: 24px;
  }
}

.addButton {
  color: map-get($colors, actionable-green);
}

.clearFilters {
  @include font($size: 14px, $lineHeight: 17px, $weight: bold, $color: actionable-green);
  letter-spacing: 0.3px;
  cursor: pointer;
}

.presetFilter {
  @include row(flex-start, center);
  @include font($size: 12px, $lineHeight: 14px, $weight: semibold, $color: midnight);
  display: inline-flex;
  border: 1px solid map-get($colors, midnight);
  padding: 5px 8px;
  border-radius: 40px;
  cursor: pointer;
  &Active {
    color: map-get($colors, white);
    background-color: map-get($colors, actionable-green);
    border-color: map-get($colors, actionable-green);
  }
}

.tag {
  @include row(flex-start, center);
  gap: 2px;
  @include font($size: 12px, $lineHeight: 14px, $weight: semibold, $color: white);
  background-color: map-get($colors, actionable-green);
  border-radius: 4px;
  padding: 3px 3px 3px 5px;
  margin: 2px;
  max-width: 120px;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.checkmark {
  min-width: 24px;
  height: 24px;
  margin-right: 8px;
}
