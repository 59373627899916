@import 'styles/base.scss';

.name {
  @include font($size: 12px, $lineHeight: 20px, $weight: semibold, $color: grey-9);
}

.mainNote {
  @include row();
  gap: 16px;
  margin-top: 4px;
  word-break: break-word;
}

.note {
  @include font($size: 16px, $lineHeight: 24px, $weight: medium, $color: midnight);
  word-break: break-word;
  white-space: pre-wrap;
}

.details {
  @include row(flex-start, center);
  gap: 8px;
  margin-top: 24px;
  margin-bottom: 4px;
}

.time {
  @include font($size: 12px, $lineHeight: 20px, $weight: medium, $color: grey-9);
}

.expandBtn {
  display: block !important;
  text-decoration: underline;
  margin: 24px auto 0 !important;
  &:hover {
    text-decoration: underline;
  }
}
