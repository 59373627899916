@import 'styles/base.scss';

.base {
  width: 690px;
}

.cardGroup {
  width: 100%;
}

.body {
  padding: 0 32px;
}

.footer {
  padding: 0 32px 32px;
}

.label {
  @include row(flex-start, center);
  @include font($size: 18px, $lineHeight: 24px, $weight: semibold, $color: midnight);
  margin: 8px 0;
  gap: 8px;

  &:not(:first-of-type) {
    margin-top: 56px;
  }
}

.sectionHeader {
  position: relative;
  padding-left: 32px;
  margin-bottom: 16px;
  text-align: left;

  .sectionTitle {
    @include font($size: 16px, $lineHeight: 24px);
  }
  .sectionSubtitle {
    @include font($size: 14px, $lineHeight: 24px, $color: grey-10);
  }

  svg {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.dateTitle {
  @include font($size: 18px, $lineHeight: 22px, $weight: medium);
  margin-bottom: 16px;
  text-align: left;
}

.timesSelectedLabel {
  @include font($size: 16px, $lineHeight: 24px, $color: grey-10);
  text-align: left;
}

.bottomLinkcontainer {
  @include font($size: 14px, $lineHeight: 18px);
  margin: 16px 0;
  a {
    cursor: pointer;
    @include font($size: 14px, $lineHeight: 18px, $weight: bold);
  }
}

.timeTypeLink {
  @include font($weight: bold, $color: actionable-green);
  text-align: left;
}

.requestItemContainer {
  position: relative;

  .iconsContainer {
    @include row(flex-end, center);
    gap: 16px;
    position: absolute;
    top: 0;
    right: 0;

    svg {
      color: map-get($colors, actionable-green);
    }
  }

  .chipsGroup {
    max-width: 100%;
    overflow: auto;
  }
}

.addAnotherDay {
  @include row(space-between, center);
  @include font($size: 18px, $lineHeight: 24px, $weight: bold, $color: actionable-green);
  cursor: pointer;
  user-select: none;

  &--disabled {
    pointer-events: none;
    opacity: 0.3;
    color: map-get($colors, midnight);
  }
}

.line {
  margin-top: 4px;
  @include font($size: 16px, $lineHeight: 24px, $weight: semibold, $color: midnight);
}

.addButton {
  width: fit-content;
}

.breakline {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin: 16px 0;
}

.calendarSwitch {
  @include row(flex-start, center);
  @include font($size: 14px, $lineHeight: 24px, $color: midnight);
  background-color: map-get($colors, grey-1);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  gap: 4px;
  float: right;
}

.therapist {
  @include row(flex-start, center);
  gap: 16px;
  .name {
    @include font($size: 16px, $lineHeight: 24px, $color: midnight, $weight: bold);
    @include row(flex-start, center);
    gap: 16px;
  }

  .avatar {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }

  .officeChip {
    margin-top: 4px;
    padding: 5px 8px 5px 5px;
    @include row(flex-start, center);
    display: inline-flex;
    gap: 4px;
    @include font($size: 12px, $lineHeight: 14px, $color: midnight, $weight: semibold);
    border-radius: 40px;
    border: 1px solid map-get($colors, midnight);
  }
}
