@import 'styles/base.scss';

.base {
  width: 725px;

  .dropzone {
    cursor: pointer;
    border: 3px dashed #dedede;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 36px 24px;
    text-align: center;

    p {
      @include font($size: 24px, $weight: bold);
      margin-top: 8px;
    }
  }

  .filesContainerLabel {
    @include font($size: 14px, $color: grey-5);
  }

  .filesContainer {
    .fileBlock {
      width: 100%;
      margin-bottom: 24px;
    }

    .file {
      @include row(space-between, center);
      padding: 8px;
      background-color: map-get($colors, natural);
      margin-bottom: 16px;
      border-radius: 4px;
      height: 56px;
      flex: 1;

      .fileDeleteIcon {
        cursor: pointer;

        &:hover {
          color: map-get($colors, error);
        }
      }

      .fileCheckmarkIcon {
        // color: map-get($colors, midnight);
      }
    }

    .extraFields {
      @include row(center, flex-start);
    }
  }
}

.fields {
  margin-bottom: 80px;
}

.header,
.body,
.footer {
  padding-left: 30px;
  padding-right: 30px;
}

.header {
  padding-top: 0px;
}

.footer {
  padding-bottom: 30px;
}
