@import 'styles/base.scss';

.base {
}

.customActions {
  margin-bottom: 32px;
}

.actions {
  @include column(stretch, stretch);
  padding-top: 60px;
  margin-top: 40px;
  margin-bottom: 40px;
  border-top: solid 1px map-get($colors, grey-2);

  & > button {
    min-height: 42px;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px !important;
    }
  }
}
