@import 'styles/base.scss';

.base {
  width: 720px;
}

.section {
  margin-top: 30px !important;

  &:first-of-type {
    margin-top: 0px !important;
  }
}

.title {
  @include font($size: 20px, $lineHeight: 22px, $weight: bold);
}

.rejectButton {
  margin-top: 16px !important;
}

.toggleOriginal {
  @include font($size: 16px, $weight: bold, $color: grey-5);
  text-decoration: underline;
  margin: 6px 0px;
  cursor: pointer;
}

.originalText {
  @include font($size: 16px, $lineHeight: 24px, $color: grey-4);
  background-color: map-get($colors, grey-1);
  border-radius: 6px;
  padding: 14px;
}

.soapNotesContainer {
  .noteContent {
    margin-top: 8px;
    .noteTitle {
      @include font($weight: bold);
    }
    .noteDescription {
      margin-bottom: 16px;
    }
  }
}

.footer {
  margin-top: 80px;
}
